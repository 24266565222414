.app__footer {
    flex: 1;
    width: 100%;
    flex-direction: column;
    background-color: var(--secondary-color);

    @media screen and (max-width: 600) {
      flex-direction: column;
    }

    .loading_circle {
      display: none;
      position: relative;
      aspect-ratio: 1;
      margin-bottom: 2rem;
    }

    .letter {
      position: absolute;
      top: 0;
      left: 50%;
      font-family: var(--font-base);
      color: var(--primary-color);
    }
  }

  .app__footer-header {
    .head-text {
        padding-top: 2rem;
        text-align: center;
        color: var(--primary-color);
    }

    .p-text{
        padding-bottom: 2rem;
        text-align: center;
        color: var(--primary-color);
    }
  }

  .app__footer-message {
    width: 100vw;
    padding-top: 1rem;
    text-align: center;
    background-color: var(--primary-color);


    .p-text {
      text-align: center;
      color: var(--secondary-color);
    }
  
    img {
      margin: 1rem;
      height: 80px;
    }
    

    a {
        text-decoration: none;
        font-weight: 800;
  
        transition: all 0.3s ease-in-out;
  
        &:hover {
            color: rgb(45, 52, 38);
        }
      }

      .app__footer-fine-print {
        padding: 5px;
        border-block-start: 1px solid var(--secondary-color); 
        width: 100%;
        // background: var(--primary-color);
    
        .p-text{
          text-align: center;
          color: var(--secondary-color);
        } 
      }
  }

  
  
  .app__footer-form {
    width: 60%;
    flex-direction: column;
    margin: 1rem 2rem;
  
    div {
      width: 100%;
  
      margin: 0.75rem 0;
      border-radius: 7px;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.1);
  
      transition: all 0.3s ease-in-out;

      ::placeholder {
        color: var(--primary-color);
        opacity: 1; /* Firefox */
      }
      
        ::-ms-input-placeholder { /* Edge 12 -18 */
            color: var(--primary-color);
        }

      
  
      input,
      textarea {
        width: 100%;
        padding: 0.95rem;
        border: none;
        border-radius: 7px;
        background-color: rgba(255, 255, 255, 0.1);
  
        font-family: var(--font-base);
        color: var(--primary-color);
        outline: none;
        
      }
  
      textarea {
        height: 170px;
      }
  
      &:hover {
        box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
      }


    }


    button {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: rgba(255, 255, 255, 0.6);
  
      font-weight: 500;
      color: var(--black-color);
      outline: none;
      margin: 2rem 0 0 0;
      font-family: var(--font-base);
  
      transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
      cursor: pointer;
  
      &:hover {
        background-color: var(--primary-color);
      }
    }
  
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 1rem 0;
    }
  }

.head-text-response {
    color: var(--primary-color);
    padding: 2rem;      
}