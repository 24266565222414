#home {
    position: relative;
    width: 100%;
  }
  
  .app__header {
    flex: 1;
    height: 100%;
    flex-direction: column;
  
    padding: 9.3rem 0rem 0rem;
  
    @media screen and (max-width: 600px) {
      padding: 7.5rem 0rem 0rem;
    }
  }
  
  .app__header-info {
    background-color: var(--secondary-color);
    flex: 1;
    display: flex;
    width: 100%;

    .scrolling-container {
      padding-top: 10px;
      padding-bottom: 10px;
      overflow: hidden;
      white-space: nowrap;

      .head-text {
        color: var(--primary-color);
        animation: marquee 10s linear infinite;
        display: inline-block;
        padding-right: 2rem;
      }
    }
    
    
    
    @keyframes marquee {
      from {
        transform: translateX(0);
      }
      to { 
        transform: translateX(-100%);
      }
    }

    // .m-scroll {
    //   display: flex;
    //   position: relative;
    //   width: 100vw;
    //   padding-top: 10px;
    //   padding-bottom: 10px;
    //   overflow: hidden;
    //   z-index: 1;
    
    //     &__title {
    //     align-items: center;
    //     // top: 0;
    //     // left: 0;
    //     // position: absolute;
    //     // display:flex;
    //     justify-content: flex-start;
    //     width: 100%;
    //     white-space: nowrap;
    //     transition: all 2s ease;
    //     & > div {
    //       display: flex;
    //       animation: scrollText 10s infinite linear;
    //     }
    //     p {
    //       color: var(--primary-color); //rgba(255,255,255,1);
    //       transition: all 1s ease;
    //       }
    //   }
    // }
  
    // @keyframes scrollText {
    //   from   { transform: translateX(0%); }
    //   to { transform: translateX(-50%); }
    // }
//     .app__header-title {
//         padding-top: 10px;
//         padding-bottom: 10px;
//         // //animation
//         -moz-transform: translateX(100%);
//         -webkit-transform: translateX(100%);
//         transform: translateX(100%);
        
//         -moz-animation: my-animation 10s linear infinite;
//         -webkit-animation: my-animation 10s linear infinite;
//         animation: my-animation 10s linear infinite;

//         .head-text {
//             color: var(--primary-color);
//             // right: 0;
//             display: flex;
//             white-space: nowrap;
//             width: 100vw;
//             overflow: hidden;
//         }
//     }
  }

//   /* for Firefox */
// @-moz-keyframes my-animation {
//     from { -moz-transform: translateX(100%); }
//     to { -moz-transform: translateX(-100%); }
//   }
  
//   /* for Chrome */
//   @-webkit-keyframes my-animation {
//     from { -webkit-transform: translateX(100%); }
//     to { -webkit-transform: translateX(-100%); }
//   }
  
//   @keyframes my-animation {
//     from {
//       -moz-transform: translateX(100%);
//       -webkit-transform: translateX(100%);
//       transform: translateX(100%);
//     }
//     to {
//       -moz-transform: translateX(-100%);
//       -webkit-transform: translateX(-100%);
//       transform: translateX(-100%);
//     }
// }
  
  .app__header-img {
    flex: 1;
    height: 100%;
    width: 100%;
  
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
  
    img {
      width: 100%;
      object-fit: contain;
      justify-content: right;
    // //   z-index: 1;
    
    }

  }