.app__digitalgallery {
    flex: 1;
    height: 100%;
    flex-direction: column;
  
    padding: 9.3rem 0rem 0rem;
    @media screen and (max-width: 600px) {
      padding: 7.5rem 0rem 0rem;
    }

    .app__header-info{
        flex-direction: column;
    }

    .app__digitalgallery-header { 
        padding: 10px;
        color: var(--black-color);

        .head-text {
            color: var(--primary-color);
        }

    }

    .app__photos {
        .p-text { 
            text-align: center;
            padding: 10px;
        }

        .loading_circle {
            position: relative;
            aspect-ratio: 1;
            margin-bottom: 4rem;
          }
      
          .letter {
            position: absolute;
            top: 0;
            left: 50%;
            font-family: var(--font-base);
            color: var(--secondary-color);
          }
    }
    
    .app__photos-filters {
        margin: 20px;
        .bold-text {
            text-align: center;
        }

        .btnContainer {
            display: flex; 
            cursor: pointer;
            margin: 5px;   
            flex-wrap: wrap;
            justify-content: center;

            .btn {
                cursor: pointer;
                font: var(--font-base);
                border-radius: 12px;
                border: 1px solid var(--black-color);
                background-color: var(--primary-color);
                padding: 5px;
                margin: 5px;
                color: var(--black-color);

                
                .p-text {
                    font-weight: normal;
                }

                &:hover {
                    background: rgba(#297d24, .2);
                }

                &.active {
                    background: var(--black-color);
                    color: var(--primary-color);

                    &:hover {
                        background: rgba(#0d1e00, 1);
                    }
                }

            }
        }
    }
}