.app__newslettergallery {
    flex: 1;
    height: 100%;
    flex-direction: column;
  
    padding: 9.3rem 0rem 0rem;
    @media screen and (max-width: 600px) {
      padding: 7.5rem 0rem 0rem;
    }

    .app__header-info{
        flex-direction: column;

        .app__newslettergallery-header { 
            padding: 10px;
    
            .head-text {
                text-align: center;
                color: var(--primary-color);
            }    
        }

    }

    .loading_circle {
        position: relative;
        aspect-ratio: 1;
        margin-bottom: 4rem;
    }

    .letter {
        position: absolute;
        top: 0;
        left: 50%;
        font-family: var(--font-base);
        color: var(--secondary-color);
    }

    .app__issues-summary{
        background-color: var(--primary-color);
        padding: 10px;
        position: center;

        .bold-text{
            text-align: center;
            padding-top: 20px;
        }

        .p-text{
            text-align: center;
            padding: 10px;
            padding-left: 10%;
            padding-right: 10%;

        }
    }


    .app__issues-covers{
        // flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-wrap: wrap;
        margin-top: 1rem;


        .app__issues-item{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            padding: 1rem;
            margin: 1rem;
            text-align: center;


            img {
                width: 250px;
            }
            
            h2 {
                align-self: center;
            }
        }
    }



}