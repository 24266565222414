.app__navbar {
    width: 100%;
  
    display: flex;
    justify-content: flex-start;//space-between;
    align-items: center; //flex-start
    flex-direction: column;
  
    padding: 0.5rem 2rem;
    background: rgba(245, 234, 219, 0.5);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 2;

  }
  
  .app__navbar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  
    img {
        display: inline-block;
        
      width: 30%;
  
      @media screen and (min-width: 2000px) {
        width: 180px;
        height: 20px;
      }
    }

    #logo-name {
      width: 230px;
      min-height: 80px;
    }
  }
  
  .app__navbar-links {
    flex: 1;
    display: flex;
  
    li {
      list-style-type: none;
      margin: 0 2rem;
      cursor: pointer;
      flex-direction: column;
      text-decoration: none;

      div {
        width: 10px;
        height: 10px;
        background: transparent;
        border-radius: 50%;
        // margin-bottom: 1px;
        text-decoration: none;

      }
  
      a {
        text-align: center;
        padding: 5px;
        min-width: 100px; 
        text-align: center;
        border-radius: 10px;
        border: 1px solid var(--secondary-color);
        background: var(--secondary-color);
        color: var(--primary-color);
        text-decoration: none;
        flex-direction: column;
  
        text-transform: uppercase;
        font-weight: 500;
  
        transition: all 0.3s ease-in-out;
  
        &:hover {
            background: rgba(#244409, 1);
        }
      }

    }
  
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  
  .app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    top: 5px;
    right: 5px;
    position: absolute;

  
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
  
  
    svg {
      width: 60%;
      height: 60%;
      color: var(--primary-color);
    }
  
    div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
  
      padding: 1rem;
  
      width: 80%;
      height: 100vh;
      
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
            
      background-color: rgba(245, 234, 219, 0.98);
      background-size: cover;
      background-repeat: repeat;
  
      /* top box shadow */
      box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);
  
      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        margin: 0.5rem 1rem;
      }
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
  
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
  
        li {
          margin: 1rem;
  
          a {
            color: var(--secondary-color);
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;
  
            transition: all 0.3s ease-in-out;
  
            &:hover {
              color: var(--black-color);
            }
          }
        }
      }
  
      @media screen and (min-width: 600px) {
        display: none;
      }
    }
  
    @media screen and (min-width: 600px) {
      display: none;
    }
  }