.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__profiles {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 1rem;

  }
  .app__profile-item:nth-child(even) {
    flex-direction: row-reverse;
    .profile-content {
        .p-text {
            text-align: right;
        }
        .bold-text {
            text-align: right;
        }
    }
  }

  .app__profile-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    padding: 1rem;
    margin: 2rem;
    margin-left: 10%;
    margin-right: 10%;

    border: 1px solid var(--secondary-color);
    border-radius: 15px;


    .profile-content {
        flex-direction: column;
        p {
          white-space: pre-wrap;
        }
        .bold-text{
          margin-Top: 15px;
        }

    }
  
    img {
      width: 40%;
      height: 200px;
      border-radius: 15px;
      object-fit: cover;
      margin: 1rem;
    }

    @media screen and (max-width: 700px) {
        align-items: center;
        .profile-content .bold-text{
          margin-Top: 0px;
        }
    }
  }



  